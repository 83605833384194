export const ACTION_TYPES = {
    Questions: {
        ADD_QUESTIONS: "ADD_QUESTIONS",
        CREATE_QUESTION: "CREATE_QUESTION",
        FETCH_QUESTIONS_PAGE: "FETCH_QUESTIONS_PAGE",
        RESET_QUESTIONS: "RESET_QUESTIONS",
        SET_TOTAL_PAGES: "SET_TOTAL_PAGES",
        SET_QUESTION_SEARCH: "SET_QUESTION_SEARCH",
        UPDATE_QUESTION: "UPDATE_QUESTION"
    },
    User:  {
        LOGIN_USER: "LOGIN_USER",
        LOGOUT_USER: "LOGOUT_USER"
    }
}